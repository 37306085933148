import { Grid, Typography, Button, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import CircleIcon from '@mui/icons-material/Circle';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ErrorIcon from '@mui/icons-material/Error';
import { DataGrid } from '@mui/x-data-grid';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import "./LogisticsScreen.css";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Link } from 'react-router-dom';
import API from '../API';
import JYSKLogo from '../components/JYSKLogo';
import { DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import OrderIDTableCell from '../components/OrderIDTableCell';
const shortcutsItems = [
    {
        label: 'Bugün',
        getValue: () => {
          const today = dayjs();
          return [today.startOf('day'), today.endOf('day')];
        },
      },
    {
      label: 'Bu Hafta',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('week'), today.endOf('week')];
      },
    },
    {
      label: 'Geçen Hafta',
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, 'day');
        return [prevWeek.startOf('week'), prevWeek.endOf('week')];
      },
    },
    {
      label: 'Son 7 Gün',
      getValue: () => {
        const today = dayjs();
        return [today.subtract(7, 'day'), today];
      },
    },
    {
      label: 'Bu Ay',
      getValue: () => {
        const today = dayjs();
        return [today.startOf('month'), today.endOf('month')];
      },
    },
    { label: 'Her zaman', getValue: () => [null, null] },
  ];

const VEHICLE_NAMES = ["Motorsiklet", "Sedan", "Doblo", "Minivan", "Panelvan", "Kamyonet"];

var STATUS = {};
STATUS[0] = {
    t: "Sürücü Aranıyor",
    color: "rgb(80,72,229)"
}
STATUS[1] = {
    t: "Planlanmış",
    color: "rgb(80,72,229)"
}
STATUS[2] = {
    t: "Aktif Sipariş",
    color: "orange"
}
STATUS[10] = {
    t: "Tamamlanmış",
    color: "green"
}
STATUS[11] = {
    t: "Müşteri İptal",
    color: "red"
}
STATUS[-1] = STATUS[11];
STATUS[12] = {
    t: "Sürücü İptali",
    color:"crimson"
}
export default class LogisticsScreen extends React.Component {

    constructor() {
        super();

        this.state = {analytics: [0,0,0,0],filterStatus: [true,true,true,true,true,false],orders: [],orderCount: 0};
    }

    getOrders(page) {

        var statusFilter = [];

        if(this.state.filterStatus[0]) statusFilter.push(-1);
        if(this.state.filterStatus[1]) statusFilter.push(0);
        if(this.state.filterStatus[2]) statusFilter.push(1);
        if(this.state.filterStatus[3]) statusFilter.push(2);
        if(this.state.filterStatus[4]) statusFilter.push(10);

        var otherFilter = {};


        if(this._idFilter != null) otherFilter._id = this._idFilter;
        if(this.dateFilter != null) otherFilter.date = this.dateFilter;




//        console.log(statusFilter)
        API.fetch("getorders", {page, statusFilter,otherFilter: otherFilter, meOnly: this.state.filterStatus[5]}).then((e) => {
            if(e.orderCount) this.state.orderCount = e.orderCount;

            //console.log();
            this.setState({orders: e.orders.map((x, i) => ({...x, id: x._id}))});
        })
    }
    componentDidMount() {
        API.fetch("getlogistics").then((e) => {
            
            this.setState({analytics: e.analytics});
        })

        this.getOrders();
    }
    render() {
        return <div>

            <div style={{display:"flex", flex:1,justifyContent:"space-between",marginBottom:10}}>
            <Typography variant='h4' style={{marginBottom: 30}}>Lojistik</Typography>

            <div style={{display:"flex", gap: 10}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div>
                <p>Sipariş Veriliş Tarihi</p>
                <DateRangePicker onAccept={(range) => {
                   

                    if((range[0] == null && range[1] == null) || (range[0] != null && range[1] != null)) {

                        if(range[0] != null) {
                        var firstRange = new Date(range[0].$d);

                        firstRange.setHours(0,0,0);
        
                        var secRange = new Date(range[1].$d);
                        secRange.setHours(23,59,59);
                        
                        this._idFilter = {$gte: Math.floor(firstRange.getTime() / 1000).toString(16) + "0000000000000000",$lte: Math.floor(secRange.getTime() / 1000).toString(16) + "0000000000000000"}
                        } else {
                            this._idFilter = null;
                        }
                        this.getOrders();
                    }

                 


                }} slotProps={{shortcuts: {items: shortcutsItems}, actionBar: {actions: []}}} slots={{field: SingleInputDateRangeField}} localeText={{ start: 'Check-in', end: 'Check-out' }} />
                </div>

                <div>
                <p>Ayarlanmış Tarih</p>
                <DateRangePicker onAccept={(range) => {
                   

                    if((range[0] == null && range[1] == null) || (range[0] != null && range[1] != null)) {

                        if(range[0] != null) {
                        var firstRange = new Date(range[0].$d);

                        firstRange.setHours(0,0,0);
        
                        var secRange = new Date(range[1].$d);
                        secRange.setHours(23,59,59);
                        
                        this.dateFilter = {$gte: firstRange.getTime(),$lt: secRange.getTime()}
                        } else {
                            this.dateFilter = null;
                        }
                        this.getOrders();
                    }

                 


                }} slotProps={{shortcuts: {items: shortcutsItems}, actionBar: {actions: []}}} slots={{field: SingleInputDateRangeField}} localeText={{ start: 'Check-in', end: 'Check-out' }} />
                </div>
            </LocalizationProvider>
            </div>
            </div>
            {/* <div style={{flexDirection:"row", display:"flex",flexWrap:"wrap"}}> */}
            <Grid spacing={"12px"} container>
                <LogisticAnalytic first icon={<CircleIcon sx={{color: 'rgb(209, 67, 67)'}} />} backgroundColor={"rgba(209, 67, 67, 0.1)"} title={"Aktif siparişler"} value={this.state.analytics[0]} />
                <LogisticAnalytic icon={<DoneAllIcon sx={{color: 'rgb(134,197,82)'}} />} backgroundColor={"rgba(134,197,82,.1)"} title={"Gün içinde tamamlanmış siparişler"} value={this.state.analytics[1]} />
                {/* <LogisticAnalytic icon={<WarningIcon sx={{color: 'rgb(209, 67, 67)'}} />} backgroundColor={"rgba(209, 67, 67, 0.1)"} title={"Hata bildirimleri"} value={"38"} />
                 */}
                <LogisticAnalytic icon={<ErrorIcon sx={{color: 'rgb(255,176,32)'}} />} backgroundColor={"rgba(255,176,32,.1)"} title={"Sürücü bekleyen siparişler"} value={this.state.analytics[2]} />
                
                <LogisticAnalytic icon={<QueryBuilderIcon sx={{color: 'rgb(80,72,229)'}} />} backgroundColor={"rgba(80,72,229,.1)"} title={"Planlanmış siparişler"} value={this.state.analytics[3]} />
            </Grid>


            <div>
            <FormGroup style={{display:"flex", flexDirection:"row"}}>
            <FormControlLabel control={<Checkbox onChange={(e) => { this.state.filterStatus[0] = e.currentTarget.checked; this.getOrders() }} defaultChecked />} label="İptal" />
            <FormControlLabel control={<Checkbox onChange={(e) => { this.state.filterStatus[1] = e.currentTarget.checked; this.getOrders()}} defaultChecked />} label="Sürücü Arıyor" />
  <FormControlLabel control={<Checkbox onChange={(e) => { this.state.filterStatus[2] = e.currentTarget.checked; this.getOrders()}} defaultChecked />} label="Planlanmış" />
  
  
  <FormControlLabel control={<Checkbox onChange={(e) => { this.state.filterStatus[3] = e.currentTarget.checked; this.getOrders()}} defaultChecked />} label="Aktif" />
  <FormControlLabel control={<Checkbox onChange={(e) => { this.state.filterStatus[4] = e.currentTarget.checked;this.getOrders() }} defaultChecked />} label="Tamamlanmış" />
</FormGroup>
            </div>

            <FormGroup style={{display:"flex", flexDirection:"row"}}>
            
  <FormControlLabel control={<Checkbox onChange={(e) => { this.state.filterStatus[5] = e.currentTarget.checked;this.getOrders() }} />} label="Sadece Üstümdeki İşler" />
</FormGroup>

            {/* </div> */}


            <Badge style={{marginTop:50,padding:0}}>
                <div style={{padding:24}}>
                <Typography variant='h6'>Son Siparişler</Typography>
                
                </div>
               
            <DataGrid
                
                autoHeight
                rows={this.state.orders}
                columns={[{field: "ID",cellClassName: (params) => (params.row.assignedWorker?.name == API.user.name?"assignedRow":undefined),flex:1,renderCell:(params) => <OrderIDTableCell order={params.row} />, valueGetter: (params,row) => "#"+row._id.toString().substring(18).toUpperCase()},
                    {field: "DURUM",cellClassName: (params) => (params.row.assignedWorker?.name == API.user.name?"assignedRow":undefined),flex:1, renderCell: (params) => {
                    const v = STATUS[params.row.status];
                    return <><div style={{paddingTop:5, paddingBottom:5, paddingLeft:10, paddingRight:10,borderRadius:20,width:"fit-content",height:"fit-content", backgroundColor:STATUS[params.row.status].color}}>
                    <Typography sx={{color:"white",fontWeight:"700"}} variant='body2'>{STATUS[params.row.status].t}</Typography>
                </div>{params.row.invoiceDetails != undefined && params.row.invoiceDetails.status != 10 && <div style={{paddingTop:5,marginLeft:5, paddingBottom:5, paddingLeft:10, paddingRight:10,borderRadius:20,width:"fit-content",height:"fit-content", backgroundColor:params.row.invoiceDetails.status==1?'yellow':'crimson'}}>
                    <Typography sx={{color:"white",fontWeight:"700"}} variant='body2'>{params.row.invoiceDetails.status == 1?"F. Onay":"F. Yok"}</Typography>
                </div>}</>
                }},
                {field: "Araç",cellClassName: (params) => (params.row.assignedWorker?.name == API.user.name?"assignedRow":undefined),flex:1, valueGetter: (params,row) => VEHICLE_NAMES[row.vehicle]},
                {field: "Şehir", cellClassName: (params) => (params.row.assignedWorker?.name == API.user.name?"assignedRow":undefined),flex: 1, valueGetter: (params,row) => API.getCityName(row.city)},
                {field: "Nereden", cellClassName: (params) => (params.row.assignedWorker?.name == API.user.name?"assignedRow":undefined),flex: 1, valueGetter: (params,row) => API.allowedCities[row.city].ilceler[row.from.subId]},
                {field: "Nereye", cellClassName: (params) => (params.row.assignedWorker?.name == API.user.name?"assignedRow":undefined),flex: 1, valueGetter: (params,row) => API.allowedCities[row.city].ilceler[row.to.subId]},
                {field: "Sürücü", cellClassName: (params) => (params.row.assignedWorker?.name == API.user.name?"assignedRow":undefined),flex: 1, renderCell: (params) => <a style={{textDecoration:"underline", color:"#0000EE"}} href={params.row.driverInfo?.name?"../driver/" + params.row.driverInfo._id:undefined}>{params.row.driverInfo?.name || ""}</a>},
                {field: "Ayar. Tarih",cellClassName: (params) => (params.row.assignedWorker?.name == API.user.name?"assignedRow":undefined),flex:1, valueGetter: (params,row) => new Date(row.date).toLocaleString("tr-TR")},
                {field: "Veriliş Tarih",cellClassName: (params) => (params.row.assignedWorker?.name == API.user.name?"assignedRow":undefined),flex:1, valueGetter: (params,row) => new Date(parseInt(row._id.substring(0, 8), 16) * 1000).toLocaleString("tr-TR")},
                {field: " ",cellClassName: (params) => (params.row.assignedWorker?.name == API.user.name?"assignedRow":undefined), renderCell: (params) => {
                    
                    return <a href={'../order/'+params.row._id}><Button><ArrowForwardIcon /></Button></a>
                    // return <Link to={'../order/' + params.row._id} ><Button><ArrowForwardIcon /></Button></Link>
                }}]}
                initialState={{pagination: {paginationModel: {pageSize: 50, page: 0}}}}
                slotProps={{pagination: {showFirstButton: true, showLastButton: true}}}
                onPaginationModelChange={(model) => {
                    // this.setState({orders: []});
                    this.getOrders(model.page);
                }}
                pageSizeOptions={[50]}
                paginationMode='server'
                rowCount={this.state.orderCount}
                sx={{"& .MuiDataGrid-columnHeaders": {
                    backgroundColor:"#F3F4F6",
                    borderRadius:0,
                    
                }}}
            />
            </Badge>
        </div>
    }
}

const Badge = (props) => {
    return <div style={{backgroundColor:"white",display:"flex",flexDirection:"column", flex:1,boxShadow:"rgb(100 116 139 / 6%) 0px 1px 1px, rgb(100 116 139 / 10%) 0px 1px 2px", borderRadius:8,padding:24, color:"rgb(18, 24, 40)",...props.style}}>
        {props.children}
    </div>
}

const LogisticAnalytic = (props) => {
    return <Grid item xs={6} sm={3} ><Badge>
        <div style={{display:"flex", alignItems:"center"}}>
            <div style={{backgroundColor: props.backgroundColor, width:40, height:40, borderRadius:8, justifyContent:"center", alignItems:"center", display:"flex"}}>
                {props.icon}
            </div>

            <Typography style={{marginLeft:10}} variant='h5'>{props.value}</Typography>
        </div>
        <Typography style={{marginTop:15,color:"rgb(101, 116, 139)",fontWeight:"400"}} variant='body2'>{props.title}</Typography>
    </Badge>
    </Grid>
}