import React from 'react';

export default class HomeScreen extends React.Component {

    constructor() {
        super();
    }
    render() {
        return <div style={{overflow:"hidden"}}>Home Screen

<div style={{display:"flex",flex:1,overflow:"hidden"}}>
<iframe style={{flex:1, height: 800,overflow:"none"}} src="https://docs.google.com/spreadsheets/d/10vmVi0K29XsnJ2It5i70HYf3id6-t4E63VZhy819I9g/edit?usp=sharing&embedded=true&rm=demo" seamless frameborder="0"  ></iframe>

</div>

            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
            <p>Home Screen</p>
        </div>
    }
}